export const enum Collections {
  Banners = "banners",
  Categories = "categories",
  Comments = "comments",
  Sections = "sections",
  Settings = "settings",
  Pages = "pages",
  News = "news",
  Gallery = "galeryHome",
  GroupFiles = "groupFiles",
}
